import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Col, Container, Row, Input, Label } from "reactstrap";
import toast from "react-hot-toast";
import contactImage from "../../assets/images/contact.png";
import magicJson from "../../magicJson/magicJson.js";

const ContactContent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    comments: "",
  });
  const [emailError, setEmailError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      const emailRegex =
        /^(?!.*(\.\.|\.\-|\-\.|--|_\-|\.\_|\-\.|\.\+|\+\.|--|\+\+|__|%%|\+\%|\%\.|\%\-|\-\%))([a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*)@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!value) {
        setEmailError("");
      } else if (!emailRegex.test(value)) {
        setEmailError("Invalid email address");
      } else {
        setEmailError("");
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const regex =
      /^(?!.*(\.\.|\.\-|\-\.|--|_\-|\.\_|\-\.|\.\+|\+\.|--|\+\+|__|%%|\+\%|\%\.|\%\-|\-\%))([a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*)@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleSubmit = () => {
    try {
      if (
        formData.name === "" ||
        formData.email === "" ||
        formData.subject === "" ||
        formData.comments === ""
      ) {
        toast.remove();
        toast.error("All fields are required!");
        return;
      }

      if (validateEmail(formData.email)) {
        setEmailError("");
      } else {
        toast.remove();
        toast.error("Invalid email");
        setEmailError("Please enter a valid email address!");
        return;
      }

      const data = new FormData();

      magicJson.endpoint = "forms";
      magicJson.executor = "createContactUsResponses";
      magicJson.data = [
        {
          name: formData.name,
          email: formData.email,
          subject: formData.subject,
          comments: formData.comments,
        },
      ];

      data.append("request", JSON.stringify(magicJson));
      const requestOptions = {
        method: "POST",
        body: data,
      };

      fetch(process.env.REACT_APP_API_URL, requestOptions).then((resp) => {
        setFormData({ name: "", email: "", subject: "", comments: "" });
        setEmailError("");
        toast.remove();
        toast.success("Form Submitted Successfully");
        return resp.json();
      });
    } catch (error) {
      toast.remove();
      toast.error("Something Went Wrong");
      return;
    }
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center mt-5">
            <Col lg={6}>
              <div className="section-title mt-4 mt-lg-0">
                <h3 className="title">Get in touch</h3>
                <p className="text-muted">
                  Start working with Jobcy that can provide everything you need
                  to generate awareness, drive traffic, connect.
                </p>
                <Form
                  method="post"
                  className="contact-form mt-4"
                  name="myForm"
                  id="myForm"
                >
                  <span id="error-msg"></span>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="nameInput" className="form-label">
                          Name
                        </Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Enter your name"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="emailInput" className="form-label">
                          Email
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Enter your email"
                        />
                        {emailError && (
                          <span style={{ color: "red" }}>{emailError}</span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="subjectInput" className="form-label">
                          Subject
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="subject"
                          value={formData.subject}
                          onChange={handleChange}
                          placeholder="Enter your subject"
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="meassageInput" className="form-label">
                          Your Message
                        </Label>
                        <textarea
                          className="form-control"
                          placeholder="Enter your message"
                          name="comments"
                          value={formData.comments}
                          onChange={handleChange}
                          rows="3"
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-end">
                    <button
                      type="button"
                      id="submit"
                      name="submit"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                      disabled={emailError !== ""}
                    >
                      Send Message <i className="uil uil-message ms-1"></i>
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col lg={5} className="ms-auto order-first order-lg-last">
              <div className="text-center">
                <img src={contactImage} alt="" className="img-fluid" />
              </div>
              <div className="mt-4 pt-3">
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-map-marker"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">
                      2453 Clinton Street, Little Rock, California, USA
                    </p>
                  </div>
                </div>
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-envelope"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">contactus@Jobcy.com</p>
                  </div>
                </div>
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-phone-alt"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">(+245) 223 1245</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="map">
        <iframe
          title="maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509157.364974411!2d-123.79641389801948!3d37.193115265681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1628684675253!5m2!1sen!2sin"
          height="350"
          style={{ border: `0`, width: `100%` }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </React.Fragment>
  );
};

export default ContactContent;
