import React from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import parse from "html-react-parser";

const BlogText = ({ searchResults }) => {
  // Function to replace <h1> with <p>
  const replaceH1WithP = (htmlString) => {
    if (htmlString === null) {
      return;
    }
    return parse(htmlString, {
      replace: (domNode) => {
        if (
          domNode.name === "h3" ||
          domNode.name === "h1" ||
          domNode.name === "h2" ||
          domNode.name === "h4"
        ) {
          return (
            <p>
              {domNode.children
                .map((child, index) => child.data || child)
                .join("")}
            </p>
          );
        }
      },
    });
  };

  return (
    <React.Fragment>
      <Row>
        {searchResults.map((blogTextDetails, key) => {
          const email = blogTextDetails.createdBy;
          const truncatedEmail =
            email.length > 30 ? `${email.slice(0, 30)}...` : email;
          return (
            
            <Col lg={6} className="mb-4" key={key}>
              <Card className="blog-grid-box blog-grid-box-height p-2">
                <img
                  src={blogTextDetails.image?.url}
                  alt="image is not available"
                  className="object-fit-cover"
                  style={{ height: "200px" }}
                />
                <CardBody className="position-relative cb-height">
                  <ul className="list-inline d-flex justify-content-between mb-0">
                    <li className="list-inline-item">
                      <p className="text-muted mb-0">
                        {/* <Link
                        to={`/blogdetails/${blogTextDetails._id}`}
                        className="text-muted fw-medium"
                      >
                        {blogTextDetails.createdBy}
                      </Link> */}
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip">{email}</Tooltip>}
                        >
                          <Link
                            to={`/blogdetails/${blogTextDetails._id}`}
                            className="text-muted fw-medium"
                          >
                            {truncatedEmail}
                          </Link>
                        </OverlayTrigger>
                        <p>
                          {"  "}
                          {blogTextDetails.createdAt?.slice(0, 10)}
                        </p>
                      </p>
                    </li>
                    <li className="list-inline-item">
                      <p className="text-muted mb-0">
                        <i className="mdi mdi-eye"></i>{" "}
                        {blogTextDetails.views ? blogTextDetails.views : 0}
                      </p>
                    </li>
                  </ul>
                  <Link
                    to={`/blogdetails/${blogTextDetails._id}`}
                    className="primary-link"
                  >
                    <h6 className="fs-16">
                      {blogTextDetails?.title.length > 60
                        ? blogTextDetails?.title.slice(0, 60) + "..."
                        : blogTextDetails?.title}
                    </h6>
                  </Link>
                  <p className="text-muted">
                    {replaceH1WithP(
                      blogTextDetails?.content?.length > 80
                        ? `${blogTextDetails?.content?.slice(0, 80) + "..."}`
                        : blogTextDetails?.content
                    )}
                  </p>
                  <div>
                    <Link
                      to={`/blogdetails/${blogTextDetails._id}`}
                      className="form-text text-primary position-absolute bottom-0"
                      style={{ marginBottom: "10px" }}
                    >
                      Read More <i className="uil uil-angle-right-b"></i>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}

        {/* {blogVideo.map((blogVideoDetails, key) => (
          <Col lg={6} mb={4} key={key}>
            <Card className="blog-grid-box p-2">
              <div className="ratio ratio-16x9">
                
                <iframe
                  src={blogVideoDetails.blogLink}
                  title="YouTube video"
                  allowFullScreen
                ></iframe>
              </div>
              <CardBody>
                <ul className="list-inline d-flex justify-content-between mb-3">
                  <li className="list-inline-item">
                    <p className="text-muted mb-0">
                      <Link to="/blogauther" className="text-muted fw-medium">
                        {blogVideoDetails.blogAuther}
                      </Link>
                      - {blogVideoDetails.blogDate}
                    </p>
                  </li>
                  <li className="list-inline-item">
                    <p className="text-muted mb-0">
                      <i className="mdi mdi-eye"></i>
                      {blogVideoDetails.blogCount}
                    </p>
                  </li>
                </ul>
                <Link to="/blogdetails" className="primary-link">
                  <h6 className="fs-17">{blogVideoDetails.blogTitle}</h6>
                </Link>
                <p className="text-muted">{blogVideoDetails.blogContent}</p>
                <div>
                  <Link to="/blogdetails" className="form-text text-primary">
                    Read More <i className="uil uil-angle-right-b"></i>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))} */}
      </Row>
    </React.Fragment>
  );
};

export default BlogText;
