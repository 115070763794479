import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import toast from "react-hot-toast";
import magicJson from "../../../magicJson/magicJson";

const TeamPage = () => {
  const [teamData, setTeamData] = useState();

  const getTeamdata = async () => {
    magicJson.endpoint = "company";
    magicJson.executor = "getAllMember";

    const data = new FormData();
    data.append("request", JSON.stringify(magicJson));

    const requestOptions = {
      method: "POST",
      body: data,
    };

    await fetch(process.env.REACT_APP_API_URL, requestOptions)
      .then((resp) => resp.json())
      .then((resp) => {
        let arr = [];
        for (let i = 0; i < resp["data"][0].length; i++) {
          if (resp["data"][0][i]["currentlyActive"]) {
            arr.push(resp["data"][0][i]);
          }
        }
        setTeamData(arr);
      });
  };

  useEffect(() => {
    getTeamdata();
  }, []);

  const handleClick = () => {
    toast.remove();
    toast.error("Work in progress!");
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row>
            {teamData?.length ? (
              teamData.map((teamPageDetails, key) => (
                <Col lg={4} md={6} key={key}>
                  <div className="team-box card border-0 mt-4">
                    <div className="team-img position-relative mx-auto">
                      <img
                        src={teamPageDetails.image.url}
                        alt=""
                        className="img-thumbnail"
                      />
                      <ul className="team-social list-unstyled">
                        <li>
                          <Link to="#" onClick={handleClick}>
                            <i className="mdi mdi-facebook"></i>
                          </Link>
                        </li>
                        <li className="my-1">
                          <Link to="#" onClick={handleClick}>
                            <i className="mdi mdi-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#" onClick={handleClick}>
                            <i className="mdi mdi-skype"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="team-content card-body text-center">
                      <Link to="/candidatedetails" className="primary-link">
                        <h6 className="fs-17 mb-1">
                          {teamPageDetails.employeeName}
                        </h6>
                      </Link>
                      <p className="text-muted mb-0">
                        {teamPageDetails.employeeRole}
                      </p>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className="mt-4">No Team Members Found!</div>
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default TeamPage;
