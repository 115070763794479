import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import magicJson from "../../magicJson/magicJson";
import toast from "react-hot-toast";

const FormContent = () => {
  const [form, setForm] = useState({});

  useEffect(() => {
    call();
  }, []);

  const call = async () => {
    try {
      const data = new FormData();

      magicJson.endpoint = "assessmentForms";
      magicJson.executor = "getForms";

      data.append("request", JSON.stringify(magicJson));
      const requestOptions = {
        method: "POST",
        body: data,
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL,
        requestOptions
      );
      const result = await response.json();
      setForm(result?.data[0][0] ? result.data[0][0] : {});
    } catch (error) {
      toast.remove();
      toast.error("Error fetching form data!");
    }
  };

  const handleFormSubmit = () => {
    const formElement = document.getElementById("dynamicForm");

    const labelTexts = Array.from(formElement.querySelectorAll("label"))
      .map((label) => label.textContent.trim())
      .slice(2);

    if (formElement) {
      const inputs = formElement.querySelectorAll("input, select, textarea");
      const formData = Array.from(inputs).map((input, index) => ({
        question: labelTexts[index],
        answer: input.value,
      }));

      magicJson.endpoint = "assessmentForms";
      magicJson.executor = "createUserResponseForFormFrontend";
      magicJson.formData = formData;
      magicJson.questionFormUUID = form.uuid;

      const data = new FormData();
      data.append("request", JSON.stringify(magicJson));

      fetch(process.env.REACT_APP_API_URL, { method: "POST", body: data })
        .then((resp) => resp.json())
        .then(() => {
          toast.remove();
          toast.success("Form submitted successfully!");
        })
        .catch((error) => {
          toast.remove();
          toast.error("Failed to submit the form!");
        });

      inputs.forEach((input) => {
        if (input.type === "checkbox" || input.type === "radio") {
          input.checked = false;
        } else {
          input.value = "";
        }
      });
    } else {
      toast.remove();
      toast.error("Form not found!");
    }
  };

  useEffect(() => {
    // Set up the event listener after the form HTML has been set
    if (form.formHTML) {
      // Ensure the form is re-rendered and re-processed after HTML is updated
      const formElement = document.getElementById("dynamicForm");
      if (formElement) {
        const submitButton = formElement.querySelector("button[type='submit']");
        if (submitButton) {
          submitButton.addEventListener("click", (event) => {
            event.preventDefault(); // Prevent default form submission
            handleFormSubmit();
          });
        }
      }
    }
  }, [form]);

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center">
            {form.formHTML ? (
              <div
                id="dynamicForm"
                dangerouslySetInnerHTML={{ __html: form?.formHTML }}
              />
            ) : (
              <p>No form Found!</p>
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default FormContent;
