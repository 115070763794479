import React from "react";
import Section from "./Section";
import FormContent from "./FormContent";

const CustomForms = () => {
  document.title = "Contact | Jobcy";
  return (
    <React.Fragment>
      <Section />
      <FormContent />
    </React.Fragment>
  );
};

export default CustomForms;
